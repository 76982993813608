import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TASKS_SHOWN_INITIALLY } from "../../Constants";
import { TaskProps } from "../../types/taskTypes";

const initialState: { 
    activeTaskType: string;
    activeTaskTag: {
        value: string,
        icon_ligature: string
      };
    activeTaskLevel: string;
    numberOfTasksDisplayed: number;
    isSearchInputExtended: boolean;
    isSearching: boolean;
    searchResults: Array<TaskProps> | null;
} = {
    activeTaskType: "all",
    activeTaskTag: {
        value: "all",
        icon_ligature: "tag"
      },
    activeTaskLevel: "B1",
    numberOfTasksDisplayed: TASKS_SHOWN_INITIALLY,
    isSearchInputExtended: false,
    isSearching: false,
    searchResults: null
};

export const FeedSlice = createSlice({
  name: "feed",
  initialState,
  reducers: {
    setActiveTaskType: (state, action: PayloadAction<string>) => {
        state.activeTaskType = action.payload;
    },
    setActiveTaskTag: (state, action: PayloadAction<{value:string, icon_ligature:string}>) => {
      state.activeTaskTag = action.payload;
    },
    setActiveTaskLevel: (state, action: PayloadAction<string>) => {
        state.activeTaskLevel = action.payload;
    },
    setNumberOfTasksDisplayed: (state, action: PayloadAction<number>) => {
      state.numberOfTasksDisplayed = action.payload;
    },
    setIsSearchInputExtended: (state, action: PayloadAction<boolean>) => {
      state.isSearchInputExtended = action.payload;
    },
    setIsSearching: (state, action: PayloadAction<boolean>) => {
      state.isSearching = action.payload;
    },
    setSearchResults:  (state, action: PayloadAction<Array<TaskProps> | null>) => {
      state.searchResults = action.payload;
    },
  },
});

export const { 
  setActiveTaskType,
  setActiveTaskTag, 
  setActiveTaskLevel, 
  setNumberOfTasksDisplayed, 
  setIsSearchInputExtended,
  setIsSearching,
  setSearchResults
} = FeedSlice.actions;