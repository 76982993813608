import React, {useEffect, useState, useRef} from 'react'
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import {intervalToDuration} from 'date-fns'
import TimerIcon from '@mui/icons-material/Timer';
import { setInterval } from 'timers/promises';
import { useAppDispatch, useAppSelector } from '../../../../../store/store';
import { setActiveSlide, setIsSlideDone, setIsTaskCompleted, setTypedAnswer, subtractSkipFromUserPerformance } from '../../../../../store/features/taskPlayerSlice';

interface Props{
  seconds: number;
  customClasses?: string;
  handleSubmit?: () => void | null; //For tasks that have only one view for all slides (E.g.: writing_dnd), when time is over all answers are submitted
  wasSubmitted?: boolean;
}

export default function Timer({seconds, customClasses, handleSubmit, wasSubmitted}: Props) {

    const [timeLeft, setTimeLeft] = useState(seconds)
    const [isVisible, setIsVisible] = useState(true)
    const [formattedTime, setFormattedTime] = useState("")
    const dispatch = useAppDispatch();
    const slides = useAppSelector((state) => state.taskPlayer.slides)
    const activeSlideIndex = useAppSelector((state) => state.taskPlayer.activeSlideIndex)
    const timerWrapperRef = useRef<HTMLDivElement | null>(null)

    let countDownInterval: any;

    useEffect(() => {
      if(wasSubmitted){
        clearInterval(countDownInterval);
        setIsVisible(false);
      }
    }, [wasSubmitted])
    

    useEffect(() => {
      clearInterval(countDownInterval);
      setTimeLeft(seconds)
      
      timerWrapperRef?.current?.classList.add("opacity-0");

      setTimeout(() => {
          timerWrapperRef?.current?.classList.remove("zoomInAndOut", "timeOverAnimation", "opacity-0");
          timerWrapperRef?.current?.classList.add("fade-in-animation");
      }, 1000);

      setTimeout(() => {
        timerWrapperRef?.current?.classList.remove("fade-in-animation");
      }, 2000);


    }, [activeSlideIndex])
    

    useEffect(() => {
      
      if(timeLeft >= -1){
        countDownInterval = window.setInterval(() => {

          const minutes = Math.floor(timeLeft / 60);
          let seconds = (timeLeft % 60).toString();
          if(minutes > 0 && Number(seconds) < 10){
            seconds = "0" + seconds;
          }

          let formatted = minutes > 0 ? `${minutes}:${seconds}` : `${seconds}`;
          
          setTimeLeft((timeLeft) => timeLeft - 1)
          setFormattedTime(formatted)

          if(timeLeft <= 5){
            if(timerWrapperRef.current){
                timerWrapperRef.current.classList.add("zoomInAndOut");
            }
          }
          if(timeLeft < 1){
              if(timerWrapperRef.current){
                timerWrapperRef.current.classList.remove("zoomInAndOut");
                timerWrapperRef.current.classList.add("timeOverAnimation");
              }
          }
        }, 1000)


      }else{
        clearInterval(countDownInterval);
        dispatch(setActiveSlide(activeSlideIndex + 1))

        if(activeSlideIndex >=  slides.length - 1){
          dispatch(setIsSlideDone(true))
          dispatch(setIsTaskCompleted(true))
        }

        if(!handleSubmit){
          dispatch(subtractSkipFromUserPerformance())
        }else{
          setIsVisible(false)
          handleSubmit();
        }
      }
    
      return () => {
        clearInterval(countDownInterval)
      }
    }, [timeLeft])
 
  if(!isVisible){
    return <></>
  }

  if(formattedTime){
    return (
      <div className={`absolute right-0 top-3 grid justify-center fade-in z-[200] ${customClasses}`}>
          <div className='relative'>
              <div ref={timerWrapperRef} className="w-[60px] h-[60px] grid justify-center items-center">
                      <div className='absolute w-full h-full font-bold text-lg grid items-center justify-center pt-[2px] pr-[1px]'>
                          <span className='text-white bg-secondary'>
                            
                            {formattedTime}
                          </span>
                      </div>
                      <TimerIcon className=' text-secondary !w-full !h-full !stroke-[0.7px] dark:stroke-neutral-300 stroke-neutral-100'/> 
                      
              </div>
          </div>
      </div>
    )
  }else{
    return <></>
  }
}


{/* <AccessAlarmIcon className='!w-[25px] !h-[25px] absolute bottom-4 -right-8'/> */}