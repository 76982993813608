import Avatar from "@mui/material/Avatar";
import { useAppSelector } from '../../store/store';

export default function UserAvatar(props:{src?:string,width?:string}) {

  
  const userData = useAppSelector((state) => state.auth.user);
  let isAvatarSticker = false;
  if(userData?.avatar_file_name){
    isAvatarSticker = userData.avatar_file_name.indexOf("sticker") > 0 ? true : false;
  }

  if(userData == undefined){
    return <></>
  }

  let finalSrc = "";
  if(props.src !== undefined){
    finalSrc = props.src;
  }else if(userData.avatar_file_name !== null){
    finalSrc = `../../img/avatars/${userData.avatar_file_name}`;
  }

  return (
  <div className={`${isAvatarSticker && "!p-0 !overflow-visible"}
    img-wrapper grid justify-center relative p-[5px]`}>

    <Avatar
      alt={userData?.name}
      src={finalSrc}
      sx={{
        width: props.width,
        height: props.width
      }}
      className={`flex text-center items-center`}
    />

  </div>
  )
}