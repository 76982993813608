import {useEffect, useState} from 'react'
import { useQuery } from "react-query";
import { TaskDone } from "../../../../types/taskTypes";
import Loader from "../../../../components/utils/Loader";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import TaskDoneCard from './../../components/TaskDoneCard';
import { getTasksDoneByLevel } from '../../../../api/dashboard';
import { ACTIVITY_EXTRA_TASKS_DONE_ON_LOAD_MORE } from '../../../../Constants';
import { setAllActivityNumberOfTasksDisplayed, setShowNavbar } from '../../../../store/features/uiSlice';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';

export default function ActivityAll() {

  const dispatch = useAppDispatch();
  const level = useAppSelector((state) => state.auth.user!.level);
  const activityActiveTab = useAppSelector((state) => state.ui.activityActiveTab);
  const allNumberOfTasksDisplayed = useAppSelector((state)=> state.ui.allActivityNumberOfTasksDisplayed)
  const [levelTasksLeftToLoad, setLevelTasksLeftToLoad] = useState(0)
  const [isFetchingMore, setIsFetchingMore] = useState(false)

  
  const {data:tasksData, isLoading: isLoadingLevelTasks, refetch: fetchLevelTDs} = useQuery(
    ["lastTasksDone", {
      level, 
      allNumberOfTasksDisplayed
    }], 
    () => getTasksDoneByLevel({level, hide_failed_ones: "1", number_of_tasks: allNumberOfTasksDisplayed}),
    {enabled:false, keepPreviousData: true,
    onSuccess: (data) =>{
      setLevelTasksLeftToLoad(data.tasks_left_to_load)
      if(isFetchingMore){
        setTimeout(() => {
          window.scrollTo({left: 0, top: window.scrollY + 300, behavior:"smooth"})
          setIsFetchingMore(false)
        }, 100);
      }
    }}
    );

  useEffect(() => {
    window.scrollTo(0,0);
    dispatch(setShowNavbar(true));
  }, [])
  
  useEffect(() => {
    if(activityActiveTab == "all"){
      fetchLevelTDs();
    }
  }, [activityActiveTab, allNumberOfTasksDisplayed])
  

  const handleLoadMoreTasks = () => {
    setIsFetchingMore(true)
    
    dispatch(
    setAllActivityNumberOfTasksDisplayed(
        allNumberOfTasksDisplayed + ACTIVITY_EXTRA_TASKS_DONE_ON_LOAD_MORE)
    )
  }

  if (isLoadingLevelTasks) {
    return (
      <div className="w-full text-center pt-10">
        <Loader  customClasses='!text-primary !w-12 !h-12' />
      </div>
    );
  }

  return (
    <>
      {tasksData && tasksData.tasks.length == 0 && 
        <div className="pt-8 text-lg text-neutral-700 dark:text-neutral-200">
          <p>No activity records were found.</p>
        </div>
      }

      <div className='p-4 lg:px-[20%]'>
          {tasksData && tasksData.tasks.map((td:TaskDone, index: number) => {
            if(td.task !== null){
              return (
                <TaskDoneCard key={td.id} td={td} showGrade={activityActiveTab == "user"}/>
              )
            }
          })}

          {levelTasksLeftToLoad > 0 &&
            <div className="px-8 pb-4 flex justify-center">
              <button
                onClick={() => { handleLoadMoreTasks() } }
                className="form-submit-btn load-more-btn">
                {isFetchingMore && <Loader customClasses='!text-white !w-6 !h-6' />}
                {!isFetchingMore &&
                  <span>Load more <ExpandCircleDownIcon sx={{ mb: "2px" }} /></span>}
              </button>
            </div>}
      </div>
    </>
  )
}
