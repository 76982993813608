import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { SlideProps, TaskProps } from "../../types/taskTypes";

interface Props {
  activeTask: TaskProps | null;
  slides: Array<SlideProps>;
  activeSlideIndex: number;
  activeWordIndex: number;
  typedAnswer: string;
  wrongAnswerSubmitted: string | null;
  wereSlidePointsSubtracted: boolean;
  isAudioPlaying: boolean;
  isSlideDone: boolean;
  user_performance: number;
  isTaskCompleted: boolean;
  taskCompletedData: null | {
    points_won: number;
  }
  playRingCompleted: boolean;
  isStepperVisible: boolean;
}

const initialState: Props = {
  activeTask: null,
  slides: [],
  activeSlideIndex: 0,
  activeWordIndex: 0,
  typedAnswer: "",
  wrongAnswerSubmitted: null,
  wereSlidePointsSubtracted: false,
  isAudioPlaying: false,
  isSlideDone: false,
  user_performance: 100,
  isTaskCompleted: false,
  taskCompletedData: null,
  playRingCompleted: false,
  isStepperVisible: true
};

export const TaskPlayerSlice = createSlice({
  name: "tasks",
  initialState,
  reducers: {
    setActiveTask: (state, action: PayloadAction<TaskProps | null>) => {
      state.activeTask = action.payload;
      state.typedAnswer = "";
      state.playRingCompleted = false;
      state.user_performance = 100;
      state.wereSlidePointsSubtracted = false;
      state.isAudioPlaying = false;
      state.wrongAnswerSubmitted = null;
      state.isSlideDone = false;
      state.isStepperVisible = true;
      if(action.payload?.slides){
        state.slides = action.payload.slides;
      }
    },
    setSlides: (state, action: PayloadAction<SlideProps[]>) => {
      state.slides = action.payload;
    },
    setActiveSlide: (state, action: PayloadAction<number>) => {
      state.activeSlideIndex = action.payload;
      if(state.isSlideDone && 
        state.activeSlideIndex == state.slides.length){
          state.isTaskCompleted = true;
      }
      state.isSlideDone = false;
      state.typedAnswer = "";
      state.wereSlidePointsSubtracted = false;
    },
    setActiveWordIndex: (state, action: PayloadAction<number>) => {
      state.activeWordIndex = action.payload
    },
    setTypedAnswer: (state, action: PayloadAction<string>) => {
      if(!state.isSlideDone){
        state.typedAnswer = action.payload;
      }
    },
    setWrongAnswerSubmitted: (state, action: PayloadAction<string>) => {
      state.wrongAnswerSubmitted = action.payload;

      if(!state.wereSlidePointsSubtracted){
        let lostPercentagePoints = 100 / state.slides.length;
        state.user_performance = state.user_performance - lostPercentagePoints;
        state.wereSlidePointsSubtracted = true;
      }
    },
    setIsAudioPlaying: (state, action: PayloadAction<boolean>) => {
      state.isAudioPlaying = action.payload
    },
    subtractSkipFromUserPerformance: (state) => {
      let lostPercentagePoints = 100 / state.slides.length;
      state.user_performance = state.user_performance - lostPercentagePoints;
    },
    setUserPerformance: (state, action: PayloadAction<number>) => {
      state.user_performance = action.payload;
    },
    setIsSlideDone: (state, action: PayloadAction<boolean>) => {
      state.isSlideDone = action.payload;
    },
    setIsTaskCompleted: (state, action: PayloadAction<boolean>) => {
      state.isTaskCompleted = action.payload;
      state.isStepperVisible = false;
    },
    setTaskCompletedData: (state, action: PayloadAction<null | { points_won: number; stars: number; score: number; }>) => {
      state.taskCompletedData = action.payload;
      state.slides = [];
      state.isAudioPlaying = false;
    },
    setPlayRingCompleted: (state, action: PayloadAction<boolean>) => {
      state.playRingCompleted = action.payload
    },
    setIsStepperVisible: (state, action: PayloadAction<boolean>) => {
      state.isStepperVisible = action.payload;
    }
  },
});

export const {
  setActiveTask,
  setSlides,
  setActiveSlide,
  setActiveWordIndex,
  setTypedAnswer,
  setWrongAnswerSubmitted,
  setIsAudioPlaying,
  subtractSkipFromUserPerformance,
  setUserPerformance,
  setIsSlideDone,
  setIsTaskCompleted,
  setTaskCompletedData,
  setPlayRingCompleted,
  setIsStepperVisible
} = TaskPlayerSlice.actions;
