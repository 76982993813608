import { Link } from "react-router-dom";
import {Dispatch, SetStateAction} from 'react'
import { LOGO_FILENAME } from "../../Constants";

export default function NavbarBrand({setIsOpen}: {setIsOpen: Dispatch<SetStateAction<boolean>>}) {
  return (
    <Link to="/" className="flex items-center" onClick={() => setIsOpen(false)}>
      <img src={`../../img/${LOGO_FILENAME}`} className="h-8 mr-2 rounded-full" alt="" />
      <span className="navbar-brand sm:inline-block">
        Green Park
      </span>
    </Link>
  );
}
