import {useEffect} from 'react'
import HomeIcon from "@mui/icons-material/Home";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import Loader from "../../utils/Loader";
import { setActiveTask, setTaskCompletedData } from "../../../store/features/taskPlayerSlice";
import { setFeedScrollPosition } from "../../../store/features/uiSlice";
import { getGradeBgColor } from "./player_services/getGradeColor";
import { API_URL } from "../../../Constants";
import Fireworks from "./slide/_animations/Fireworks";
import TaskResultGrade from "../card/TaskResultGrade";
import { Star } from "@mui/icons-material";
import StarsWonAnimation from "./animations/StarsWonAnimation";

export default function TaskCompleted() {

  const isDataLoaded = useAppSelector((state)=> state.taskPlayer.taskCompletedData);
  const taskPoints = useAppSelector((state)=> state.taskPlayer.taskCompletedData!.points_won);
  const result = useAppSelector((state) => state.taskPlayer.user_performance);
  const thumbnail_url = useAppSelector((state) => state.taskPlayer.activeTask?.thumbnail_url);
  const userData = useAppSelector((state) => state.auth.user);
  
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setFeedScrollPosition(0));

    setTimeout(() => {
      document.getElementById("btn-back-home")?.classList.remove("hidden");
    }, 1500);
    setTimeout(() => {
      document.getElementById("btn-back-home")?.classList.remove("opacity-0");
    }, 3500);
  }, [])
  

  if(!isDataLoaded){
    return <Loader />
  }

  return (
    <div className="px-2 pt-4 z-50 fade-in">
      <div className={`grid justify-center text-lg text-center rounded-lg p-4  mb-6 shadow-lg  border-2 border-neutral-200 dark:border-neutral-800/30 dark:bg-neutral-900/80 bg-neutral-100 font-bricolage`}
        >

          <div className="pb-5 pr-6 absolute -left-6 -top-6 text-center bg-[gold] border-white/50 border-2 rounded-br-full w-[120px] h-[120px] mr-2 star-pulse-animation">
            <Star 
              className="star-pulse-and-shine-hard absolute bottom-[36px] right-[44px] !w-[44px] !h-[44px]  "
            />
          </div>

        <div className={`task-card-done-sash ${getGradeBgColor(result)} `}>
            <span className="mr-2">Grade</span>
            <span className="text-sm">
              <TaskResultGrade result={result}/>
            </span>
        </div>

        <div className="rounded-lg mx-4">
          <img 
            src={API_URL + thumbnail_url} 
            className="flex justify-self-center rounded-lg border-2 border-white dark:border-neutral-700/30 max-h-[170px]" 
          />
        </div>

        <h1 className="mt-3 mb-1 white-text-with-shadow">
          TASK COMPLETED
        </h1>

      </div>

      <div className="flex relative justify-center items-end text-white dark:text-neutral-900 pt-3">
        <StarsWonAnimation totalStars={taskPoints}/>
      </div>
      
      <div id="btn-back-home" className="absolute bottom-4 left-0 w-full px-2 z-[300] hidden opacity-0 duration-1000">
        <button
          onClick={() => {
            window.history.back();
            dispatch(setTaskCompletedData(null))
            dispatch(setActiveTask(null));
          }}

          className="bg-primary text-white w-full rounded-md py-4 flex items-center justify-center"
        >
          <HomeIcon sx={{ mr: 1 }} />
        </button>
      </div>
      
      <Fireworks/>
    </div>
  );
}
