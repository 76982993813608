import { useEffect, useState} from "react";
import { useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import TaskStepper from "../../components/task/player/TaskStepper";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { setShowNavbar } from "../../store/features/uiSlice";
import { useQuery } from "react-query";
import { getSlides, getTaskLastDone } from "../../api/task";
import { setActiveSlide, setActiveTask, setTaskCompletedData } from "../../store/features/taskPlayerSlice";
import AudioPlayer from "../../components/task/player/audio/audio_player/AudioPlayer";
import { differenceInMinutes } from "date-fns";
import { CURRENT_ENV, TIME_GAP_BETWEEN_ATTEMPTS } from "../../Constants";
import TaskCardWait from "../../components/task/card/TaskCardWait";

export default function TaskPlayer() {
  const dispatch = useAppDispatch();
  const { slug } = useParams();
  const taskId = useAppSelector((state) => state.taskPlayer.activeTask?.id);
  const {data:activeTaskLastDone, isLoading: isLoadingLastDone, refetch: refetchLastDone} = useQuery(["taskLastDone", taskId], () => getTaskLastDone(taskId), {onSuccess: (data) =>{
    setTimeSinceLastDone(differenceInMinutes(new Date(), new Date(data)));  
  }});
  const slides = useAppSelector((state) => state.taskPlayer.slides)
  const activeSlideIndex = useAppSelector((state) => state.taskPlayer.activeSlideIndex)
  const slide_data = slides[activeSlideIndex];
  const taskType = useAppSelector((state) => state.taskPlayer.activeTask?.type)
  const taskFormat = useAppSelector((state) => state.taskPlayer.activeTask?.format?.value);
  const isTaskCompleted = useAppSelector((state) => state.taskPlayer.isTaskCompleted)
  const playRingCompleted = useAppSelector((state) => state.taskPlayer.playRingCompleted)
  const task_audio_url = useAppSelector((state) => state.taskPlayer.activeTask?.audio_file_url)
  const [timeSinceLastDone, setTimeSinceLastDone] = useState<number | null>(null);

  const {data, refetch: refetchSlides} = useQuery(["slides", slug], () => getSlides(slug), {
    onSuccess:(data) => {      
      dispatch(setActiveTask(data))
    },
    enabled: false
  });

  useEffect(() => {
    dispatch(setShowNavbar(false));
    dispatch(setActiveSlide(0))
    dispatch(setTaskCompletedData(null))
    window.scrollTo(0, 0);
    refetchSlides();
    refetchLastDone();
  }, []);
  

  if(timeSinceLastDone !== null && !isTaskCompleted && CURRENT_ENV !== "development"){
    if(timeSinceLastDone < TIME_GAP_BETWEEN_ATTEMPTS){
      return <TaskCardWait 
                remainingTime={TIME_GAP_BETWEEN_ATTEMPTS - timeSinceLastDone}
                mustWaitToDoAgain={true}/>
    }
  }

  
  if(data && !isLoadingLastDone){  
    return (
        <div 
          className={`task-player-wrapper h-full grid justify-center relative overflow-hidden dark:bg-black
          ${taskFormat !== 'writing_dnd_in_gaps' && 'px-2'}`}>
          <div className="flex max-w-[720px] ">
            <TaskStepper />

            {taskType == "listening" &&       
                <AudioPlayer audio_url={task_audio_url} data={slide_data}/>
            }
          </div>
        </div>
    );
  }else{
    return (
      <div className="w-full text-center pt-10">
        <CircularProgress className='!text-primary'/>
      </div>
    )
  }
}
