import {useEffect, useState} from 'react'
import PointsSubTabs from './components/ScoresSubTabs';
import { useAppSelector } from '../../store/store';
import TabUserPoints from './components/subtabs/TabUserScores';
import TabAllPoints from './components/subtabs/TabAllScores';

export default function Scores() {

    const activeTab = useAppSelector((state) => state.ui.pointsActiveTab)

    useEffect(() => {
        window.scrollTo(0,0);
    },[])

  return (
    <div className="points-section dark:text-white grid pb-32">
        {activeTab == "all" && <TabAllPoints />}
        {activeTab == "user" && <TabUserPoints />}
        <PointsSubTabs/>
    </div>
  )
}
