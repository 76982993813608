import {useState, useEffect} from 'react'
import StudentInfoRow from './components/StudentInfoRow'
import { useQuery } from 'react-query'
import { getAllStudentsByLevel, getTasksDoneByLevel } from '../../api/dashboard'
import { StudentInfoProps } from '../../types/adminTypes'
import TaskType from '../../components/task/card/TaskType'
import StudentInfoModal from './components/modal/StudentModal'
import { TaskDone } from '../../types/taskTypes'
import TaskDoneCard from '../activity/components/TaskDoneCard'
import Loader from '../../components/utils/Loader'
import { useAppDispatch, useAppSelector } from '../../store/store'
import { setSelectedLevel } from '../../store/features/adminSlice'
import { setShowNavbar } from '../../store/features/uiSlice'


export default function Dashboard() {

    const dispatch = useAppDispatch();
    const selectedLevel = useAppSelector((state) => state.admin.selectedLevel)
    const [modalIsOpen, setIsModalOpen] = useState(false);
    const [modalStudentData, setModalStudentData] = useState<StudentInfoProps | null>(null)
    const isAdmin = useAppSelector((state) => state.auth.user?.role) == "admin";
    const teacherLevel = useAppSelector((state) => state.auth.user?.level);

    const {data: studentsData, isLoading: isLoadingStudentsData} = useQuery(
        ["studentsData", {selectedLevel}], 
        () => getAllStudentsByLevel({level: selectedLevel})
    );

    const {data: lastTasksDone, isLoading: isLoadingLastTasksDone} = useQuery(
        ["lastTasksDone", {selectedLevel}], 
        () => getTasksDoneByLevel({level: selectedLevel, number_of_tasks: 20})
    );
    
    useEffect(() => {
        dispatch(setShowNavbar(true))
    }, [])
    

  return (
    <div className="dark:text-white pt-4 px-2">

        {/* Level select */}
        {isAdmin && (
            <div className="w-full grid grid-cols-12 justify-center items-center mb-4">
                <div className="col-span-12 text-center">
                    <select 
                        className="text-neutral-800 p-2 rounded-md"
                        value={selectedLevel}
                        onChange={(e) => {dispatch(setSelectedLevel(e.target.value));}}
                        >
                        <option value="b1">B1</option>
                        <option value="b2">B2</option>
                    </select>
                </div>
            </div>
        )}

        {!isAdmin && (
            <div className="w-full text-center mb-4">
                <h1><span className="uppercase font-medium text-lg">{teacherLevel}</span> Level Students</h1>
            </div>
        )}

        {/* Students info grid */}
        {isLoadingStudentsData && 
            <div className="w-full grid justify-center py-8">
                <Loader customClasses='!text-white'/>
            </div>
        }
        {!isLoadingStudentsData && studentsData?.length == 0 && 
            <p className="text-center text-lg pt-4">No registered students were found.</p>
        }
        {!isLoadingStudentsData && studentsData &&(
            <div className="grid max-h-[32vh] overflow-y-scroll mb-12 rounded-md">
                {studentsData.length > 0 &&
                <table className="dashboard-table text-white text-center">
                    <thead>
                        <tr>
                            <th>{/* Student's Avatar */}</th> 
                            <th>{/* Student's Name */}</th>
                            <th><TaskType type='listening'/></th>
                            <th><TaskType type='reading'/></th>
                            <th><TaskType type='speaking'/></th>
                            <th><TaskType type='writing'/></th>
                            <th>{/* Total Points */}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {studentsData.map((sd:StudentInfoProps) => (
                            <StudentInfoRow
                                key={sd.id}
                                data={sd} 
                                setIsModalOpen={setIsModalOpen} 
                                setModalStudentData={setModalStudentData}
                            />
                        ))}
                    </tbody>
                </table>
                }
            </div>
        )}

        <StudentInfoModal 
            modalIsOpen={modalIsOpen}
            setIsModalOpen={setIsModalOpen}
            data={modalStudentData}
        />

        {/* All admin level's students shared recent activity */}
        {isLoadingLastTasksDone &&   
            <div className="w-full grid justify-center py-8">
                <Loader/>
            </div>
        }
        {!isLoadingLastTasksDone && lastTasksDone?.length == 0 && 
            <p className="text-center text-lg pt-4">No tasks done records were found.</p>
        }
        {!isLoadingLastTasksDone && (
            <div className="activity-wrapper w-full">
                <div className="grid max-h-[32vh] overflow-y-scroll dark:text-white border-y-2 border-neutral-600  dark:bg-neutral-900">
                    {lastTasksDone && lastTasksDone.tasks.map((ltd:TaskDone, index: number) => (
                        <TaskDoneCard key={ltd.id + "-" + index} td={ltd}/>
                    ))}
                </div>
            </div>
        )}
    </div>
  )
}
