import { useState, useRef } from "react";

import { useAppDispatch, useAppSelector } from "../../store/store";
import AvatarModal from "./components/AvatarModal";
import Avatar from "@mui/material/Avatar";


export default function Settings() {

  const avatarFileName = useAppSelector((state) => state.auth.user?.avatar_file_name)

  const [imgFile, setImgFile] = useState<File | null>(null);
  const [formError, setFormError] = useState<string | null>(null);
  const imgField = useRef<any | null>();
  const [isAvatarModalOpen, setIsAvatarModalOpen] = useState(false)



  const handleImgChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormError(null);
    if (!e.target.files) return;

    const formFile = e.target.files[0];

    if (formFile.size <= 300000) {
      setImgFile(formFile);
    } else {
      setFormError("Image size must be below 300 KB");
      imgField.current.value = null;
    }
  };



  return (
    <section className="settings">
      <div className="grid grid-cols-12 pt-8">
        <div className="col-span-5 grid justify-end items-center">
            <div className="img-wrapper w-[90px] h-[90px] relative bg-neutral-200 rounded-full grid items-center justify-center">
              <div className="avatar-bg bg-white absolute top-[5%] left-[5%] w-[90%] h-[90%] rounded-full"></div>
              <Avatar src={`../../img/avatars/${avatarFileName}`}
                className="!w-[80px] !h-[80px] p-2"/>
            </div>
        </div>
        <div className="col-span-6 grid justify-start items-center pl-2">
          <button className="form-submit-btn" 
              onClick={() => {
                setIsAvatarModalOpen(true);
              }}>
            Choose New Avatar
          </button>
        </div>
      </div>
          {/* THIS WILL BE A MODAL */}
          <AvatarModal modalIsOpen={isAvatarModalOpen} setIsModalOpen={setIsAvatarModalOpen}/>


            {/* CREATE PROFILE LINK */}
    </section>
  );
}
