export const availableAvatars = [
    {
      "file_name":"b_and_w/icons8-baloncesto-30.png",
      "cost": 50
    },
    {
      "file_name":"b_and_w/icons8-caballero-30.png",
      "cost": 50
    },
    {
      "file_name":"b_and_w/icons8-cat-head-30.png",
      "cost": 50
    },
    {
      "file_name":"b_and_w/icons8-control-30.png",
      "cost": 50
    },
    {
      "file_name":"b_and_w/icons8-cute-frog-30.png",
      "cost": 50
    },
    {
      "file_name":"b_and_w/icons8-fidget-spinner-30.png",
      "cost": 50
    },
    {
      "file_name":"b_and_w/icons8-physics-30.png",
      "cost": 50
    },
    {
      "file_name":"b_and_w/icons8-flecha-cruzada-30.png",
      "cost": 50
    },
    {
      "file_name":"b_and_w/icons8-football-2-30.png",
      "cost": 50
    },
    {
      "file_name":"b_and_w/icons8-hockey-30.png",
      "cost": 50
    },
    {
      "file_name":"b_and_w/icons8-kawaii-cupcake-30.png",
      "cost": 50
    },
    {
      "file_name":"b_and_w/icons8-koala-30.png",
      "cost": 50
    },
    {
      "file_name":"b_and_w/icons8-logo-de-minecraft-30.png",
      "cost": 50
    },
    {
      "file_name":"b_and_w/icons8-mariposa-30.png",
      "cost": 50
    },
    {
      "file_name":"b_and_w/icons8-origami-30.png",
      "cost": 50
    },
    {
      "file_name":"b_and_w/icons8-primavera-30.png",
      "cost": 50
    },
    {
      "file_name":"b_and_w/icons8-red-panda-30.png",
      "cost": 50
    },
    {
      "file_name":"b_and_w/icons8-tenis-30.png",
      "cost": 50
    },
    {
      "file_name":"b_and_w/icons8-tubo-de-ensayo-30.png",
      "cost": 50
    },
    {
      "file_name":"b_and_w/icons8-unicornio-30.png",
      "cost": 50
    },



    {
      "file_name":"flat_icon/ladybug-flati.png",
      "cost": 100
    },
    {
      "file_name":"flat_icon/frog-flati.png",
      "cost": 100
    },
    {
      "file_name":"flat_icon/lion-flati.png",
      "cost": 100
    },
    {
      "file_name":"flat_icon/fox-flati.png",
      "cost": 100
    },
    {
      "file_name":"flat_icon/butterfly-flati.png",
      "cost": 100
    },
    {
      "file_name":"flat_icon/chameleon-flati.png",
      "cost": 100
    },
    {
      "file_name":"flat_icon/cat-flati.png",
      "cost": 100
    },
    {
      "file_name":"flat_icon/cat-2-flati.png",
      "cost": 100
    },


    


    {
      "file_name": "stickers/icons8-alps-100.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-bart-simpson-100.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-basketball-100.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-bass-drum-100.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-batman-100.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-bear-100.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-black-cat-100.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-black-panther-mask-100.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-butterfly-100.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-captain-america-100.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-cat-100.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-clew-100.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-close-up-100.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-corgi-100.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-cyclops-marvel-100.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-dinosaur-100.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-dog-100.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-dragon-100.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-european-dragon-100.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-flower-100-1.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-flower-100-2.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-flower-100-3.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-flower-100-4.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-flower-100-5.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-flower-100-6.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-flower-100-7.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-flower-100.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-frog-100.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-funny-100.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-gambit-100.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-genie-100.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-guitar-100.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-harry-potter-100-2.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-harry-potter-100.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-hello-kitty-100.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-homer-simpson-100.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-horse-100.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-hummingbird-100.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-iron-man-100.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-island-on-water-100.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-jersey-100.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-kawaii-dinosaur-100.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-lamb-100.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-leopard-100.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-lion-100.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-love-100.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-magneto-100.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-minecraft-pickaxe-100.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-minecraft-sword-100.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-musical-100.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-musical-notes-100.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-naruto-100.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-national-park-100.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-neo-100.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-ninja-turtle-100.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-owl-100.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-panda-100.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-piano-100.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-pokeball-100.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-r2-d2-100.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-rock-music-100.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-romantic-movies-100.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-rocket-100.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-sailboat-100.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-seed-of-life-100.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-shark-100.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-son-goku-100.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-sonic-the-hedgehog-100.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-spider-man-head-100.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-sport-100.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-stich-100.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-stormtrooper-100.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-super-mario-100.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-superman-100.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-swimming-cap-100.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-sword-100.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-the-flash-sign-100.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-thor-100.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-three-leaf-clover-100.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-tropics-100.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-turtle-100.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-unicorn-100.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-viking-ship-100.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-violin-100.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-wolf-100.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-wolverine-100.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-wonder-woman-100.png",
      "cost":200
    },
    {
      "file_name": "stickers/icons8-woody-woodpecker-100.png",
      "cost":200
    },




    
      {
        "file_name":"bubbles/icons8-apple-music-200.png",
        "cost":300
      },
      {
        "file_name":"bubbles/icons8-cascabel-200.png",
        "cost":300
      },
      {
        "file_name":"bubbles/icons8-clash-of-clans-200.png",
        "cost":300
      },
      {
        "file_name":"bubbles/icons8-cofre-del-tesoro-200.png",
        "cost":300
      },
      {
        "file_name":"bubbles/icons8-control-200.png",
        "cost":300
      },
      {
        "file_name":"bubbles/icons8-dratini-200.png",
        "cost":300
      },
      {
        "file_name":"bubbles/icons8-eevee-200.png",
        "cost":300
      },
      {
        "file_name":"bubbles/icons8-estatua-de-la-libertad-200.png",
        "cost":300
      },
      {
        "file_name":"bubbles/icons8-gafas-3d-200.png",
        "cost":300
      },
      {
        "file_name":"bubbles/icons8-greek-helmet-200.png",
        "cost":300
      },
      {
        "file_name":"bubbles/icons8-harry-potter-200.png",
        "cost":300
      },
      {
        "file_name":"bubbles/icons8-hello-kitty-200.png",
        "cost":300
      },
      {
        "file_name":"bubbles/icons8-literatura-200.png",
        "cost":300
      },
      {
        "file_name":"bubbles/icons8-logo-de-minecraft-200.png",
        "cost":300
      },
      {
        "file_name":"bubbles/icons8-love-arrow-200.png",
        "cost":300
      },
      {
        "file_name":"bubbles/icons8-manzana-200.png",
        "cost":300
      },
      {
        "file_name":"bubbles/icons8-nintendo-switch-handheld-200.png",
        "cost":300
      },
      {
        "file_name":"bubbles/icons8-novela-200.png",
        "cost":300
      },
      {
        "file_name":"bubbles/icons8-parque-nacional-200.png",
        "cost":300
      },
      {
        "file_name":"bubbles/icons8-perro-200.png",
        "cost":300
      },
      {
        "file_name":"bubbles/icons8-pikachu-pokemon-200.png",
        "cost":300
      },
      {
        "file_name":"bubbles/icons8-poppy-flower-200.png",
        "cost":300
      },
      {
        "file_name":"bubbles/icons8-super-mario-200.png",
        "cost":300
      },
      {
        "file_name":"bubbles/icons8-tubo-de-ensayo-200.png",
        "cost":300
      },
  ]