import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AuthState, LoginResponse } from "../../types/authTypes";
import { User } from "../../types/userTypes";

const accessTokenStr: string | null = localStorage.getItem("accessToken");
const userDataStr: string | null = localStorage.getItem("userData");

const userDataJson = userDataStr !== null ? JSON.parse(userDataStr) : null;
const accessTokenJson = accessTokenStr !== null ? JSON.parse(accessTokenStr) : null;

const initialState: {
  user: User | null,
  register:{
    active_step: number;
    access_code: string | null;
    level: string | null;
    user_name: string | null;
  }
} = {
  user: userDataJson,
  register:{
    active_step: 1,
    access_code: null,
    level: null,
    user_name: null
  }
};

export const AuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setRegisterActiveStep: (state, action: PayloadAction<number>) => {
      state.register.active_step = action.payload;
    },
    setRegisterAccessCode: (state, action: PayloadAction<string>) => {
      state.register.access_code = action.payload;
    },
    setRegisterLevel: (state, action: PayloadAction<string>) => {
      state.register.level = action.payload;
    },
    setUserData: (state, action: PayloadAction<LoginResponse | any>) => {
      state.user = action.payload.user;
      localStorage.setItem("userData", JSON.stringify(state.user));
    },
    updateStoredAvatar: (state, action: PayloadAction<string>) => {
      // @ts-ignore
      state.user.avatar_file_name = action.payload;
      localStorage.setItem("userData", JSON.stringify(state.user));
    },
    updateStoredStars:(state, action: PayloadAction<{stars_current:number,
      stars_historical:number}>) => {    
      // @ts-ignore
      state.user.stars_current = action.payload.stars_current;
      // @ts-ignore
      state.user.stars_historical = action.payload.stars_historical;
      localStorage.setItem("userData", JSON.stringify(state.user));
    },
    logout: (state) => {
      state.user = null
    }
  },
});

export const { 
  setRegisterActiveStep,
  setRegisterAccessCode,
  setRegisterLevel,
  setUserData, 
  updateStoredAvatar,  
  updateStoredStars, 
  logout 
} = AuthSlice.actions;
