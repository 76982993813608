import { Dispatch, SetStateAction } from "react";
import { useAppSelector } from "../../../store/store";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import NavbarAuthStarsV2 from "./NavbarAuthStarsV2";
import Avatar from "@mui/material/Avatar";

interface Props {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export default function NavbarAuth({ isOpen, setIsOpen }: Props) {
  const userData = useAppSelector((state) => state.auth.user);
  const isStudent = useAppSelector((state) => state.auth.user?.role) == "student";

  return (
    <>
      <ul className="navbar-list">
        <li className="flex items-center mr-4">
        {userData && isStudent &&
          <NavbarAuthStarsV2 />
        }
        </li>

        <li className="flex items-center">
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="flex text-white items-center justify-center"
          >   
            <span className="white-text-with-shadow font-medium mr-2">
              {userData?.name.split(" ")[0]}
            </span>

            {userData?.avatar_file_name && 
              <div className="img-wrapper">
              <Avatar
                alt={userData?.name}
                src={`../../img/avatars/${userData.avatar_file_name}`}
                sx={{
                  display: "flex",
                  width: "33px",
                  height: "33px",
                  textAlign: "center",
                  alignItems: "center",
                }}
              />
            </div>
            }
            {!userData?.avatar_file_name && 
              <AccountCircleIcon className="!w-8 !h-8"               
              sx={{
                display: "flex",
                width: "30px",
                height: "30px",
                textAlign: "center",
                alignItems: "center",
                zIndex: 200
              }}/> 
            }
          </button>
        </li>
      </ul>
    </>
  );
}
