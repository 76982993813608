import {useEffect, useRef} from 'react'
import StarIcon from "@mui/icons-material/Star";
import AddIcon from "@mui/icons-material/Add";
import { useAppSelector } from "../../../../store/store";
import UserAvatar from '../../../utils/UserAvatar';
import { API_URL } from '../../../../Constants';
import { PreloadMedia, MediaType } from 'react-preload-media';

export default function StarsWonAnimation({totalStars}:{totalStars:number}) {

    const userData = useAppSelector((state) => state.auth.user);
    let allStars = [];
    const touchCoinAudioUrl = API_URL + '/files/audio_ui/sound_eff_touch_coin.mp3';
    const touchCoinAudio = new Audio(touchCoinAudioUrl);
    const media = [{ type: MediaType.Audio, url: touchCoinAudioUrl}];

    useEffect(() => {
      setTimeout(() => {
        animateStars()
        document.getElementById("avatar-wrapper")?.classList.remove("opacity-0");
        document.getElementById("avatar-wrapper")?.classList.add("star-pulse-and-shine-hard");

        document.getElementById("stars-won-label")?.classList.remove("opacity-0");
        document.getElementById("stars-won-label")?.classList.add("font-size-animation");
      }, 3000);

      setTimeout(() => {
        for (let index = 0; index < totalStars; index++) {
            let star = document.getElementById("star-" + Number(index + 1));
            star?.classList.add("star-shine-and-rotate");
        }
      }, 5500);
    }, [])
    

    for (let index = 0; index < totalStars; index++) {
        allStars.push(
            <div 
                key={"star-key-" + index} 
                id={"star-" + Number(index + 1)} 
                className="star absolute inline left-0 bottom-0"
                onClick={(e) => {
                    animateExplosion(e)
                }}>
                <StarIcon      
                    className=" z-[200] !w-full !h-full"
/>
            </div>
        )
    }

    const animateExplosion = (e:React.MouseEvent<HTMLDivElement>) => {
        let wasExploded = e.currentTarget.classList.contains('star-explode');

        if( !wasExploded){
            let targetID = e.currentTarget.id;
        
            document.getElementById(targetID)?.classList.add("star-explode");
            if (navigator.vibrate) {
                navigator.vibrate(500);
            }
            //@ts-ignore
            touchCoinAudio.cloneNode().play();
        }
    }

    const animateStars = () => {
        let stars = document.getElementsByClassName("star");

        if(stars.length == 1){
            document.getElementById("star-1")?.classList.add("single-star-path", "star-fly-u-shape");
        }else{
            for (let index = 0; index < stars.length; index++) {
                let star = document.getElementById("star-" + Number(index + 1));
                star?.classList.add("star-"+ Number(index + 1) + "-path", "star-fly-u-shape");
            }
        }
    }

  return (
    <div className="w-full relative text-center grid justify-center pt-[2vh]">
        <div id="avatar-wrapper" className="grid w-full justify-center opacity-0 duration-1000">
            <span className="font-bold white-text-with-shadow text-lg">Well done {userData?.name.split(" ")[0]}!</span>
            <UserAvatar width='70px'/>
        </div>
        <div className="absolute top-[130px] w-full opacity-animation dark:text-white text-center mb-[10px]">
          <span id="stars-won-label" className="white-text-with-shadow font-bold text-lg opacity-0">
            <AddIcon className={"font-bold stroke-[gold] mb-1"} sx={{ strokeWidth:"2px" }} />
            {totalStars} {Number(totalStars) > 1 ? "stars" : "star"}
          </span>
        </div>

        <div className="w-full grid justify-center mt-[70px]">
            <div className="explosion-source relative w-8 h-8">
                {allStars}
            </div>
        </div>

        <PreloadMedia media={media} onFinished={() => console.log("Audio Preloading DONE")}>
        </PreloadMedia>
    </div>
    );
}
