import {useEffect, useState} from 'react'
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Slide from "./slide/Slide";
import { SlideProps } from "../../../types/taskTypes";
import { useRegisterTaskDone } from "../../../api/task";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { useMutation } from "react-query";
import TaskCompleted from "./TaskCompleted";
import { setActiveSlide, setTaskCompletedData, setIsTaskCompleted } from "../../../store/features/taskPlayerSlice";
import Loader from "../../utils/Loader";
import TaskFailed from "./TaskFailed";
import UIAudioPlayer from "./audio/ui_audio_player/UIAudioPlayer";
import { CURRENT_ENV } from "../../../Constants";
import { updateStoredStars } from '../../../store/features/authSlice';

export default function TaskStepper() {

  const dispatch = useAppDispatch();
  const slides = useAppSelector((state) => state.taskPlayer.slides);
  const task_id = useAppSelector((state) => state.taskPlayer.activeTask?.id);
  const user_performance = useAppSelector((state) => state.taskPlayer.user_performance);
  const activeSlideIndex = useAppSelector((state) => state.taskPlayer.activeSlideIndex);
  const isTaskCompleted = useAppSelector((state) => state.taskPlayer.isTaskCompleted)
  const taskCompletedData = useAppSelector((state) => state.taskPlayer.taskCompletedData);
  const taskFormat = useAppSelector((state)=>state.taskPlayer.activeTask?.format?.value)
  const isStepperVisible = useAppSelector((state) => state.taskPlayer.isStepperVisible);
  const userRole = useAppSelector((state) => state.auth.user?.role)
  const [holdForVisibilityDispatch, setHoldForVisibilityDispatch] = useState(true)

  const { mutate: registerTaskDone, isLoading: taskCompletedLoading } = useMutation(useRegisterTaskDone);

  useEffect(() => {
    setTimeout(() => {
      setHoldForVisibilityDispatch(false)
    }, 500);
  }, [])
  
  if(isTaskCompleted){
    dispatch(setIsTaskCompleted(false));
    registerTaskDone({task_id, user_performance}, { onSuccess: (data) => {      
      dispatch(setTaskCompletedData(data));
      dispatch(updateStoredStars({
        stars_current: data.stars_current,
        stars_historical: data.stars_historical
      }))  
    }});
  }

  const handleStep = (step: number) => () => {
    if(CURRENT_ENV == "development" || userRole == "admin"){
      dispatch(setActiveSlide(step));
    }
  };

  return (
    <Box sx={{ width: "100%"}} className={`${isStepperVisible && 'pt-4'}`}>
      {isStepperVisible && 
        (
            <Stepper nonLinear activeStep={activeSlideIndex} sx={{transition: "all 1s"}} className={`${holdForVisibilityDispatch && "opacity-0"}`}>
              {slides && slides.map((slide: SlideProps, index: number) => (
                <Step key={slide.id} >
                  <StepButton
                    color="inherit"
                    onClick={handleStep(index)}
                  ></StepButton>
                </Step>
              ))}
            </Stepper>
        )}
      <div className="step-content-wrapper grid justify-center h-full">
        {activeSlideIndex < slides.length && (
          <Slide/>
        )}
        {taskCompletedLoading && <Loader />}
        {taskCompletedData && user_performance >= 60 && (
          <TaskCompleted />
        )}
        {taskCompletedData && user_performance < 60 && (
          <TaskFailed />
        )}
        
        <UIAudioPlayer />
      </div>
    </Box>
  );
}
