import {useState, useEffect} from 'react'
import Prompt from "../_components/SlidePrompt";
import { SlideProps } from "../../../../../types/taskTypes";
import { useAppDispatch, useAppSelector } from "../../../../../store/store";
import Loader from "../../../../utils/Loader";
import { setActiveSlide, setIsSlideDone, setTypedAnswer, setWrongAnswerSubmitted } from "../../../../../store/features/taskPlayerSlice";
import SlideImage from '../_components/SlideImage';
import Timer from '../_components/Timer';

export default function SlideReadingMultipleAbcd({ data }: { data: SlideProps }) {
  const typedAnswer = useAppSelector((state) => state.taskPlayer.typedAnswer)
  const timerDuration = useAppSelector((state) => state.taskPlayer.activeTask?.format?.timer_duration)
  const correctAnswer = data.correct_answer;
  const activeSlideIndex = useAppSelector((state) => state.taskPlayer.activeSlideIndex)
  const taskImgUrl = useAppSelector((state) => state.taskPlayer.activeTask!.thumbnail_url)
  const [isDone, setIsDone] = useState(false)
  const dispatch = useAppDispatch();

  useEffect(() => {
    setIsDone(false)
    if(typedAnswer == correctAnswer){
      dispatch(setIsSlideDone(true))
      setIsDone(true)
      setTimeout(() => {
        dispatch(setActiveSlide(activeSlideIndex+1))
      }, 1500);
    }else{
      if(typedAnswer.length){        
        let date = new Date();
        dispatch(setWrongAnswerSubmitted(date.toString()))
      }
    }
  }, [typedAnswer])

  useEffect(() => {
    setIsDone(false)
  }, [activeSlideIndex])
  
  
  const handleTypedAnswer = (option: string) => {
    dispatch(setTypedAnswer(option));
  }

  const markAsClicked = (e: React.MouseEvent<HTMLElement>) => {
    //@ts-ignore
    e.target.classList.add("clicked");
  }


  if (data === undefined) {
    return <Loader />;
  }

  return (
    <div className="">
      <div className="relative p-3 grid justify-center">
        <Timer seconds={Number(timerDuration)}/>
        <div className="flex h-[130px] w-[220px] dark:bg-black rounded-md overflow-hidden">
          <SlideImage img_url={taskImgUrl}/>
        </div>
      </div>
      <div className={`${isDone ? "fade-out" : "fade-in-animation"}`}>
        <div className="px-4 pt-2 pb-4 text-neutral-700 dark:text-white max-h-[320px]
        overflow-y-scroll dark:bg-neutral-900 bg-neutral-50 rounded-md">
          <Prompt rawContent={data?.prompt} />
        </div>
          <div className="multiple-btn-wrapper grid grid-cols-2 gap-1">
            {data.options?.split("|").map((option:string) => (
              <div key={option} className="col-span-1 flex justify-center mb-1">
                <button 
                  className="multiple-btn"
                  onClick={(e) => {
                    markAsClicked(e);
                    handleTypedAnswer(option);
                  }}>
                  {option}
                </button>
              </div>
            ))}
          </div>
      </div>
    </div>
  );
}
