import { useQuery } from "react-query";
import { getHostLatestVersion } from "../api/user";
import { differenceInHours } from "date-fns";

export const useCheckLatestVersion = () => {

    const hoursGapBetweenChecks = 6;

    const {data: hostLatestVersion, refetch: checkHostLatestVersion } = useQuery(["hostLatestVersion"], 
    () => getHostLatestVersion(),
        {
            enabled: false,
            onSuccess: (data) => {
                let hostLatestVersion = new Date(data);

                let version_control = {
                    last_time_checked: new Date(),
                    app_version: data
                  }
                localStorage.setItem("versionControl", JSON.stringify(version_control));

                if(versionControlJson?.last_time_checked){
                    let userVersion = new Date(versionControlJson.app_version);

                    if(hostLatestVersion.toString() !== userVersion.toString()){
                        window.location.reload();
                    }
                }
            }
        }
    );

    const versionControl = localStorage.getItem("versionControl");
    const versionControlJson = versionControl !== null ? JSON.parse(versionControl) : null; 

    if(versionControlJson?.last_time_checked){
        
        if(differenceInHours(new Date(), new Date(versionControlJson.last_time_checked)) > hoursGapBetweenChecks){    
            checkHostLatestVersion()
        }
    }else{
        checkHostLatestVersion()
    }
}
