import { useState, useEffect } from "react";
import StarIcon from "@mui/icons-material/Star";
import { useAppSelector } from "../../../store/store";

export default function NavbarAuthStarsV2({showOnlyCurrent = false}: {showOnlyCurrent?: boolean}) {
  let userData = useAppSelector((state) => state.auth.user);

  return (
    <div className={`flex`}>
      <div className="bg-[gold] flex star-pulse-animation  rounded-lg  h-[32px] text-sm items-center justify-center z-[100] border-[2px] border-white py-1 px-2">

        <span className="drop-shadow-[1px_1px_1px_rgba(0,0,0,1)] text-white mr-[2px] font-bold text-[16px] leading-4 mb-[1px]">
          {Number(userData?.stars_current)}
        </span>
        <span className="drop-shadow-[1px_1px_1px_rgba(0,0,0,1)]">
            <StarIcon
                className="star-pulse-animation relative bottom-[2.5px]"
                sx={{
                width: "18px",
                height: "18px",
                color: "white",
                zIndex: 99,
                }}
            />
        </span>

      </div>
    </div>
  );
}
