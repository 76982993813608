import { useAppSelector } from "../../store/store";
import ActivitySubTabs from './components/ActivitySubTabs';
import ActivityAll from './components/tabs/ActivityAll';
import ActivityUser from './components/tabs/ActivityUser';

export default function Activity() {

  const activityActiveTab = useAppSelector((state) => state.ui.activityActiveTab);

  return (
    <div 
      className={`dashboard text-neutral-400 text-center pb-36`}>

        {activityActiveTab == "all" && <ActivityAll />}
        {activityActiveTab == "user" && <ActivityUser />}

      <ActivitySubTabs />
    </div>
  )
}
