import axios from "axios";
import { API_URL } from "../Constants";
import {getToken} from "./_getToken";
 

interface updateAvatarResp{
  user_data:{
    avatar_file_name: string
  }
}

export const updateAvatar = async ({ avatar_file_name}: {avatar_file_name: string | undefined}) => {
    let token = await getToken();
    const data = new FormData();
    if(avatar_file_name !== undefined) data.append('avatar_file_name', avatar_file_name);

    const response = await axios.post<updateAvatarResp>(API_URL + '/api/update-avatar',
    data,
    {
      headers: {
        Accept :'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    let new_avatar_file_name = response.data?.user_data?.avatar_file_name;
    
    return new_avatar_file_name;
}

export const getHostLatestVersion = async () => {
  let token = await getToken();

  const response = await axios.get(
    API_URL + "/api/get-host-latest-version",
    {
      headers: {
        Accept :'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return response.data;

  
}
