import {Dispatch, SetStateAction} from 'react'
import Modal from 'react-modal';
import CloseIcon from '@mui/icons-material/Close';
import { StudentInfoProps } from '../../../../types/adminTypes';
import { API_URL } from '../../../../Constants';
import Avatar from "@mui/material/Avatar";
import TaskType from '../../../../components/task/card/TaskType';
import { format } from 'date-fns';
import StudentInfoActivity from './StudentActivity';

interface Props{
    modalIsOpen: boolean;
    setIsModalOpen: Dispatch<SetStateAction<boolean>>
    data: StudentInfoProps | null
}

export default function StudentInfoModal(props:Props) {
  return (
    <Modal
    isOpen={props.modalIsOpen}
    contentLabel="Student Info Modal"
    className="modal-student-info"
    ariaHideApp={false}
  >
    <Avatar 
        src={API_URL + props?.data?.avatar_url} alt=""
        className="!absolute -left-[10px] -top-2 !w-[60px] !h-[60px]"
    />
    <h2 className="text-lg pl-16 py-2 mb-3 bg-primary text-white rounded-tr-md items-center">{props.data?.name}</h2>

    <div className="text-center py-2 px-4 mb-4">
        <p>Register Date: 
            {" "}
            {props.data &&
            <strong>{format(new Date(props.data!.created_at), "MMM dd, yyyy.")}</strong>
            }
        </p>
    </div>

    <div className="table-wrapper grid justify-center mb-6">
        <table className="dashboard-table text-white text-center border-neutral-700 border-2">
                    <thead>
                        <tr>
                            <th><TaskType type='listening'/></th>
                            <th><TaskType type='reading'/></th>
                            <th><TaskType type='speaking'/></th>
                            <th><TaskType type='writing'/></th>
                            <th>TOTAL</th>
                        </tr>
                    </thead>
                    <tbody>
            <tr >
                <td>{props.data?.processed_type_points?.listening}</td>
                <td>{props.data?.processed_type_points?.reading}</td>
                <td>{props.data?.processed_type_points?.speaking}</td>
                <td>{props.data?.processed_type_points?.writing}</td>
                <td className="rounded-r-md">{props.data?.processed_type_points?.total}</td>
            </tr>
            </tbody>
        </table>
    </div>

    <div className="max-h-[45vh] overflow-y-scroll rounded-md">
        <StudentInfoActivity user_id={props.data?.id}/>
    </div>

    <button 
        className="btn-close-modal"
        onClick={() => props.setIsModalOpen(false)}>
        <CloseIcon className="relative bottom-[2px] mr-1"/>
        CLOSE
    </button>
  </Modal>
  )
}
