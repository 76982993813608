import { useEffect, useState, useRef } from "react";
import { SlideProps } from "../../../../../types/taskTypes";
import { useAppDispatch, useAppSelector } from "../../../../../store/store";
import Loader from "../../../../utils/Loader";
import { checkSpeakingAnswer } from "../../player_services/checkSpeakingAnswer";
import { setActiveSlide, setActiveWordIndex, setIsSlideDone, setTypedAnswer } from "../../../../../store/features/taskPlayerSlice";
import Skipper from "../_components/Skipper";
import Synthesizer from "../../audio/synthesizer/Synthesizer";
import LazyImage from "../_components/LazyImage";
import { speakingMiddlewares } from "./middlewares/speakingMiddlewares";
import Transcriptor from "../../audio/transcriptor/Transcriptor";

export default function SlideSpeakingPictureDescription({ data }: { data: SlideProps }) {

  const scrollableDivRef = useRef< HTMLDivElement | null>(null);
  const typedAnswerRef = useRef<HTMLDivElement | null>(null)

  const typedAnswer = useAppSelector((state) => state.taskPlayer.typedAnswer);
  const activeSlideIndex = useAppSelector((state) => state.taskPlayer.activeSlideIndex);
  const slides = useAppSelector((state) => state.taskPlayer.slides)
  const correctGapAnswers = slides[activeSlideIndex].correct_answer;
  const [isDone, setIsDone] = useState(false)
  const [processedAnswer, setProcessedAnswer] = useState("")
  const [gapFillingAnswer, setGapFillingAnswer] = useState<string | null>(null)
  const dispatch = useAppDispatch();
  const [cleanTextArray, setCleanTextArray] = useState<string[]>([])
  const [rawTextArray, setRawTextArray] = useState<string[]>([])
  const activeWordIndex = useAppSelector((state) => state.taskPlayer.activeWordIndex)

  useEffect(() => {
    if(scrollableDivRef.current){
      scrollableDivRef.current.scrollTop = 0;
    }
  }, [activeSlideIndex])
  

  useEffect(() => {   
    setGapFillingAnswer(correctGapAnswers)
    setIsDone(false);
    setRawTextArray(data.prompt!.replace("---", "______").split(" "))

    let cleanStr = data.prompt!
    .replace('---', 'promptgap')
    .replace(/[^\w\s\']|_/g, "")
    .replace(/\s+/g, " ")
    .replace(/-/g, ' ')
    .replace(/'/g, '');

    setCleanTextArray(cleanStr.split(" "))
    dispatch(setActiveWordIndex(0))
    
  }, [activeSlideIndex])
  

  useEffect(() => {
    
    if(!isDone){
      if(typedAnswer.length > 0 && cleanTextArray.length && cleanTextArray[activeWordIndex] !== undefined){

        if(typedAnswer !== processedAnswer){
          let processedAnswer = speakingMiddlewares(cleanTextArray[activeWordIndex], typedAnswer);          
          
          if(processedAnswer !== typedAnswer){           
            dispatch(setTypedAnswer(processedAnswer))
            setProcessedAnswer(processedAnswer)
          }
        }

        let newActiveWordIndex = checkSpeakingAnswer({
          cleanTextArray, typedAnswer, activeWordIndex, correctGapAnswers, setGapFillingAnswer
        });
        dispatch(setActiveWordIndex(newActiveWordIndex))

        // if(newActiveWordIndex !== activeWordIndex){
        //   setTimeout(() => {
        //     typedAnswerRef?.current?.classList.add("fade-out");
        //     setTimeout(() => {
        //       dispatch(setTypedAnswer(""))
        //       typedAnswerRef?.current?.classList.remove("fade-out");
        //     }, 1000);
        //   }, 4000);
        // }
      }
    }
  }, [typedAnswer]);

  useEffect(() => {
    if(cleanTextArray.length){
      if(activeWordIndex >= cleanTextArray.length){
        dispatch(setIsSlideDone(true))
        setIsDone(true)
        
        setTimeout(() => {
          setTimeout(() => {
            dispatch(setActiveSlide(activeSlideIndex + 1))
          }, 1500);
        }, 500);
      }
    }
  }, [activeWordIndex])
  

  if (data === undefined) {
    return <Loader />;
  }

  return (
    <div className={`slide-reading ${isDone ? "fade-out" : "fade-in-animation"}`}>
      <div className="p-1 mt-2 grid justify-center min-h-[34vh]">
        <div className="flex relative w-full lg:!w-[370px] dark:bg-black rounded-md overflow-hidden">
            <LazyImage imgSrc={data.img_url} customClasses="z-[200]"/>
        </div>
      </div>

      <div ref={scrollableDivRef} className="text-justify px-6 pt-2 pb-2 text-neutral-700 dark:text-white max-h-[30vh] overflow-y-scroll">
          {rawTextArray.map((string, index) => (
            <span key={index}
              className={`
                ${index == activeWordIndex && "bg-primary text-white"}
                ${index < activeWordIndex && "bg-green-500 text-white"}
                ${string.indexOf("___") >= 0 && "text-primary"}
                p-[1px] rounded-sm text-2xl`
              }
            >
              {string.indexOf("___") >= 0 && index < activeWordIndex ? gapFillingAnswer : string}
              {" "}
            </span>
            ))}
        </div>

      <div className="px-8 pt-8 pb-2 font-bold text-lg text-center">
        {typedAnswer?.length > 0 &&
          <span ref={typedAnswerRef} className="text-white text-[20px] bg-primary p-2 rounded-md leading-8">
          {typedAnswer}
          </span>
        }
        </div>
        <Skipper />
        <Synthesizer activeWord={cleanTextArray[activeWordIndex]} />
        <Transcriptor />
    </div>
  );
}
