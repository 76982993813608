import { useRef, useEffect, useState } from "react";
import { API_URL } from "../../../../../Constants";
import { useAppDispatch, useAppSelector } from "../../../../../store/store";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import { SlideProps } from "../../../../../types/taskTypes";
import { setIsAudioPlaying } from "../../../../../store/features/taskPlayerSlice";

interface Props{
  audio_url: string | undefined;
  data: SlideProps;
}

export default function AudioPlayer({audio_url, data}: Props) {
  const audioPlayer = useRef(null);
  const activeSlide = useAppSelector((state) => state.taskPlayer.activeSlideIndex);
  const isAudioPlaying = useAppSelector((state) => state.taskPlayer.isAudioPlaying)
  let stopTimeOut = useRef<ReturnType<typeof setTimeout> | undefined>(undefined);
  const dispatch = useAppDispatch();
  const progressBarRef = useRef<HTMLDivElement | null>(null);
  const [animationDuration, setAnimationDuration] = useState("6s");
  const taskFormat = useAppSelector((state) => state.taskPlayer.activeTask?.format?.value);
  const isTaskCompleted = useAppSelector((state) => state.taskPlayer.taskCompletedData);


  useEffect(() => {
    if(data){
      // if(progressBarRef.current){
      //   progressBarRef.current.classList.remove("progress-animation");
      // }
      
      // if(isAudioPlaying){
      //   setTimeout(() => {
      //     if(progressBarRef.current){
      //       progressBarRef.current.classList.add("progress-animation");
      //     }
      //   }, 100);
      // }

      restartStopTimer()
      if(!isAudioPlaying && activeSlide > 0){
        play()
      }
    }
  }, [activeSlide, data]);

  useEffect(() => {
    if(!isAudioPlaying){
      pause()
    }else{
      if(activeSlide > 0){
        play()
      }
    }
  }, [isAudioPlaying])
  

  const play = () => {
    // if(progressBarRef.current !== null){
      
      dispatch(setIsAudioPlaying(true))
      resetTimeToStart()
      restartStopTimer()
      //@ts-ignore
      audioPlayer.current.play();

      // progressBarRef.current.classList.remove("progress-animation");

      // setTimeout(() => {
      //   if(progressBarRef.current !== null){
      //   progressBarRef.current.classList.add("progress-animation");
      //   }
      // }, 100);

    // }
  }

  const pause = () => {
    dispatch(setIsAudioPlaying(false))
    clearTimeout(stopTimeOut.current);
    if(audioPlayer.current){
      //@ts-ignore
      audioPlayer.current.pause();
      // if(progressBarRef.current){
      //   //@ts-ignore
      //   progressBarRef.current.classList.remove("progress-animation");
      // }
    }
  }

  const restartStopTimer = () => {
    
    clearTimeout(stopTimeOut.current);
    let time: number;
    //@ts-ignore
    let currentTime = audioPlayer.current.currentTime;
    //@ts-ignore
    if( Number(currentTime) > Number(data.time_start)){
      //@ts-ignore
      time = Number(data.time_span) - (Number(currentTime) - Number(data.time_start))
    }else{
      //@ts-ignore
      time = Number(data.time_span) + (Number(data.time_start) - Number(currentTime))
    }

    setAnimationDuration(time + "s");
    
    stopTimeOut.current = setTimeout(() => {
      pause()
      resetTimeToStart()
    }, time * 1000);
  }

  const resetTimeToStart = () => {
    if(audioPlayer.current){
      //@ts-ignore
      audioPlayer.current.currentTime = data.time_start
    }
  }

  return (
    <div className={`grid grid-cols-12  absolute bottom-2 left-0 right-0 justify-center items-center mt-4 pb-8 first-letter first-letter overflow-hidden border-2 dark:border-neutral-700 border-neutral-200 rounded-lg max-h-[120px]
    ${isTaskCompleted && 'hidden'}
    ${taskFormat == 'listening_one_word' && ' bottom-auto top-[200px]'}
    ${taskFormat!.indexOf('scrollable') >= 0 && '!fixed bottom-auto -top-4 z-[200] mt-0 lg:mx-80 !rounded-none'}
    `}>
      {data && 
        <>
        <div className="absolute bottom-auto z-0 w-full max-h-[120px] right-0 left-0  flex justify-center items-center">
          <div className="overlay dark:bg-black  absolute z-10 opacity-40 w-full h-full"></div>
          <img src={API_URL + data.img_url} alt="" className="flex w-full z-0 rounded-lg" />
        </div>

        {/* <div className="progress-bar-container col-span-12 absolute -bottom-2 w-full pt-0 z-[100]">
          <div className="progress progress-infinite overflow-hidden">
            <div
              ref={progressBarRef}
              className="progress-bar3 bg-secondary"
              style={{ animationDuration: animationDuration }}>
            </div>
          </div>
        </div> */}
        <div className="col-span-12 flex justify-center z-[100]">
          {!isAudioPlaying &&
            <button
              className="play-control-btn"
              onClick={play}
            >
              <PlayArrowIcon />
            </button>}
          {isAudioPlaying &&
            <button
              className="stop-control-btn pulse-animation"
              onClick={() => { pause(); resetTimeToStart(); } }
            >
              <StopCircleIcon />
            </button>}
        </div>
        </>
      }

      <audio ref={audioPlayer}>
        <source src={API_URL + audio_url} type="audio/ogg" />
      </audio>

    </div>
  );
}
