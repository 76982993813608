import { useEffect, useRef, useState } from "react";
import MicIcon from "@mui/icons-material/Mic";
import { useAppDispatch, useAppSelector } from "../../../../../store/store";
import { setTypedAnswer } from "../../../../../store/features/taskPlayerSlice";


const SpeechRecognition =
  window.webkitSpeechRecognition || window.SpeechRecognition;
const recognition = new SpeechRecognition();
recognition.lang = "en-UK";
recognition.continuous = false;
recognition.interimResults = true;

export default function Transcriptor() {

  const microphoneRef = useRef< HTMLDivElement| null>(null);
  const [isListening, setIsListening] = useState(false);
  const activeSlideIndex = useAppSelector((state) => state.taskPlayer.activeSlideIndex)
  const activeTaskId = useAppSelector((state) => state.taskPlayer.activeTask?.id)
  const dispatch = useAppDispatch();
  const isTaskCompletedDataLoaded = useAppSelector((state) => state.taskPlayer.taskCompletedData);
  const isTaskCompleted = useAppSelector((state) => state.taskPlayer.isTaskCompleted)
  const totalSlides = useAppSelector((state) => state.taskPlayer.slides)
  const [microphoneAccess, setMicrophoneAccess] = useState(true);

async function checkMicrophoneAccess() {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

    // Microphone access is granted
    console.log('Microphone access granted:', stream);
    stream.getTracks().forEach((track) => track.stop());

    setMicrophoneAccess(true);

    // You can proceed with your click-to-talk functionality here
  } catch (error: any) {
    console.error('Error accessing microphone:', error);

    if (
      error.name === 'NotAllowedError' ||
      error.name === 'PermissionDeniedError' ||
      error === 'Permission dismissed'
    ) {
      // User denied or dismissed permission, handle it as needed
      askForMicrophonePermission()
      setMicrophoneAccess(false);
    } else {
      // Handle other errors here
      console.error('Other error:', error);
    }
  }
}

  const askForMicrophonePermission = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      stream.getTracks().forEach((track) => track.stop());
      return true; // Permission granted
    } catch (error) {
      console.error('Error requesting microphone permission:', error);
      return false; // Permission denied
    }
  };


  useEffect(() => {
    checkMicrophoneAccess();
    dispatch(setTypedAnswer(""))
    if(activeSlideIndex > 0 && activeSlideIndex < totalSlides.length){
      handleListening();
    }
  }, [activeSlideIndex])

  useEffect(() => {
    if(recognition){
      recognition.stop();
    }
  }, [activeTaskId])
  

  recognition.onresult = (event) => {
    let transcript = event.results[0][0].transcript;
    handleSpokenAnswer(transcript);
  };

  recognition.onstart = () => {
    setIsListening(true);
    if(microphoneRef.current){
      microphoneRef.current.classList.add("listening");
      microphoneRef.current.classList.add("start-listening-animation");
        setTimeout(() => {
          microphoneRef.current!.classList.remove("start-listening-animation");
        }, 1000);
    }
  }

  recognition.onend = () => {
    setIsListening(false)
    if(microphoneRef.current){
      microphoneRef.current.classList.remove("listening");
    }
  };

  const handleListening = () => {
    checkMicrophoneAccess();

      if(!isListening){
        recognition.start();
      }else{
        recognition.stop();
        setIsListening(false)
      }
  };

  const handleSpokenAnswer = (transcription: string) => {      
    dispatch(setTypedAnswer(transcription));
  };

  if (!recognition) {
    return (
      <div className="mircophone-container">
        Browser does not Support Speech Recognition.
      </div>
    );
  } 
  
  
  if(isTaskCompleted || isTaskCompletedDataLoaded){
    return <></>
  }

  return (
    <div className="mircophone-container">
      <div
        className={`microphone-icon-container ${!microphoneAccess && "!bg-red-500 !text-white"}`}
        ref={microphoneRef}
        onClick={handleListening}
      >
        <MicIcon className="microphone-icon" />
      </div>

      {!microphoneAccess && 
        <div className="absolute -top-[110px] -left-[130px] bg-red-500 text-white border-2 border-white rounded-md p-2 w-[220px] z-[300]">
          <p className="text-lg text-center">You must enable access to the microphone</p>
        </div>
      }
    </div>
  );
}