import { trickyWordsMiddleware } from "./picture_description/trickyWordsMiddleware";
import { numberMiddleware } from "./picture_description/numberMiddleware";
import { finalLetterSMiddleware } from "./picture_description/finalLetterSMiddleware";
import { checkSpelledWordMiddleware } from "./picture_description/checkSpelledWordMiddleware";

export const speakingMiddlewares = (activeWord: string, givenAnswer: string) => {  
    
    let processedAnswer = givenAnswer;

        let trickyWordsMidd = trickyWordsMiddleware(activeWord, givenAnswer);
        if(trickyWordsMidd){ processedAnswer = trickyWordsMidd };

        let numberMidd = numberMiddleware(activeWord, givenAnswer);
        if(numberMidd){ processedAnswer = numberMidd };

        let finalSMidd = finalLetterSMiddleware(activeWord, givenAnswer);
        if(finalSMidd){ processedAnswer = finalSMidd };

        let spelledWordMidd = checkSpelledWordMiddleware(activeWord, givenAnswer);
        if(spelledWordMidd){ processedAnswer = spelledWordMidd };

    return processedAnswer;

}