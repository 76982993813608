import { TaskDone } from '../../../types/taskTypes'
import { capitalizeFirstLetter } from '../../../services/stringCapitalizeFirstLetter'
import { formatDistanceToNow } from 'date-fns'
import TaskType from '../../../components/task/card/TaskType'
import LazyImage from '../../../components/task/player/slide/_components/LazyImage'
import { getGradeBgColor } from '../../../components/task/player/player_services/getGradeColor'
import TaskResultGrade from '../../../components/task/card/TaskResultGrade'
import Avatar from "@mui/material/Avatar";
import { API_URL } from '../../../Constants'
import StarIcon from "@mui/icons-material/Star";
import { useNavigate } from 'react-router-dom'


export default function TaskDoneCard({td, showGrade = true}:{td:TaskDone, showGrade?:boolean}) {
  const navigate = useNavigate();

  return (
    <div key={td.id}
    onClick={() => {navigate("/" + td.task.slug)}} 
    className={`task-done-block fade-in lg:mb-20 p-1 text-neutral-700 dark:text-neutral-200 mb-8 rounded-lg overflow-hidden  border-[2px] dark:border-neutral-900/80 border-white shadow-lg dark:shadow-none dark:bg-neutral-900/70 bg-white`}>

        <div className={`grid grid-cols-12 relative text-left py-2 mb-2`}>
          <div className="col-span-2 lg:col-span-1 lg:justify-center relative grid items-center justify-center">
              <div className=" rounded-full">
              <Avatar 
                    src={`../../img/avatars/${td.user.avatar_file_name}`}
                    className="!flex rounded-full"/>
              </div>
          </div>

          <div className="col-span-8 px-1">
            <h3 className="mr-4 font-bold relative top-0">
                {td.user.name}
            </h3>
            <h4 className='relative -bottom-[2px] text-sm dark:text-neutral-400/40 text-neutral-500/50'>
              {capitalizeFirstLetter( 
                formatDistanceToNow(
                    new Date(td.created_at), 
                    {addSuffix:true})
                )
              }
            </h4>
          </div>

          <div className="col-span-2 grid items-start justify-end">
            {td.points_won > 0 && (
              <span className="flex text-[18px] font-medium items-center text-white [text-shadow:_1px_1px_1px_#000]">
                +{td.points_won}
                <StarIcon
                  sx={{
                    width: "27px",
                    height: "27px",
                    position: "relative",
                    left:"3px",
                    bottom: "0",
                    color: "gold",
                    stroke: "white",
                    strokeWidth: "0.5px"
                  }}
                />
              </span>
            )}
          </div>
        </div>

      <div
        className={`task-done-card max-h-[82px] grid grid-cols-12 relative ml-6 mr-2 lg:ml-12 mb-2  rounded-lg overflow-hidden  border-[2px] dark:border-neutral-900/80 border-neutral-50 dark:bg-neutral-800/30`}>

        <div className={`col-span-2 max-h-[82px] relative grid min-h-[70px] overflow-hidden items-center justify-center`}>
          <div className="overlay dark:bg-[rgba(0,0,0,0.3)] bg-[rgba(255,255,255,0.1)] absolute top-0 left-0 w-full h-full z-[100]"></div>
          <div className="task-card-type-icon !z-50 !w-8 !h-8 absolute top-0 left-0 !pl-[3px] !pt-[6px]">
            <TaskType type={td.task.type} />
          </div>
          <LazyImage
            imgSrc={td.task.thumbnail_url}
            customClasses={` max-w-[300%] h-full lg:max-w-full justify-self-center`} />
        </div>

        <div className={`${!showGrade && "!col-span-10"}  col-span-8 grid max-h-[82px] py-2 px-4 text-left items-center`}>
          <div className=''>
            <h4 className="text-sm font-medium mb-1 dark:text-neutral-300">{td.task.title}</h4>
            <p className="text-sm dark:text-neutral-400/40 text-neutral-500/50">{td.task_format?.instructions}</p>
          </div>
        </div>

        {showGrade && (
          <div className={`col-span-2  max-h-[82px] ${getGradeBgColor(td.result)}
            grid py-5 justify-center`}>
            <TaskResultGrade
              result={td.result}
              customClasses="!h-[35px] !w-[35px] !flex items-center" />
          </div>
        )}
      </div>
    </div>
  )
}
