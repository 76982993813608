import {useEffect, useState, useRef} from 'react'
import { useQuery } from "react-query";
import { getUserTasksDone } from "../../../../api/task";
import { TaskDone } from "../../../../types/taskTypes";
import Loader from "../../../../components/utils/Loader";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import { ACTIVITY_EXTRA_TASKS_DONE_ON_LOAD_MORE } from '../../../../Constants';
import { setShowNavbar, setUserActivityNumberOfTasksDisplayed } from '../../../../store/features/uiSlice';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import { useNavigate } from 'react-router-dom';
import TaskType from '../../../../components/task/card/TaskType';
import LazyImage from '../../../../components/task/player/slide/_components/LazyImage';
import TaskResultGrade from '../../../../components/task/card/TaskResultGrade';
import { getGradeBgColor } from '../../../../components/task/player/player_services/getGradeColor';
import { capitalizeFirstLetter } from '../../../../services/stringCapitalizeFirstLetter';
import { formatDistanceToNow } from 'date-fns';
import Avatar from "@mui/material/Avatar";

export default function ActivityUser() {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const cardsWrapperRef = useRef< HTMLDivElement | null>(null)
  const scrollPosition = useAppSelector((state) => state.ui.dashboardScrollPosition);
  const userId = useAppSelector((state) => state.auth.user!.id);
  const userAvatarFileName = useAppSelector((state) => state.auth.user!.avatar_file_name);
  const userName = useAppSelector((state) => state.auth.user!.name);
  const level = useAppSelector((state) => state.auth.user!.level);
  const activityActiveTab = useAppSelector((state) => state.ui.activityActiveTab);
  const userNumberOfTasksDisplayed = useAppSelector((state)=> state.ui.userActivityNumberOfTasksDisplayed)

  const [isScrollSet, setIsScrollSet] = useState(false);
  const [tasksDone, setTasksDone] = useState< Array<TaskDone> | null>(null)
  const [userTasksLeftToLoad, setUserTasksLeftToLoad] = useState(0)
  const [isFetchingMore, setIsFetchingMore] = useState(false)

  const { isLoading: isLoadingUserTasks, refetch: fetchOnlyUserTDs, isRefetching: isRefetchingUserTasks } = useQuery(
    ["userTasksDone", {userId, userNumberOfTasksDisplayed}], 
    () => getUserTasksDone({
      user_id: userId, 
      number_of_tasks: userNumberOfTasksDisplayed
    }),
    {enabled: false, keepPreviousData: true,
    onSuccess: (data) =>{
      setTasksDone(data.tasks)
      setUserTasksLeftToLoad(data.tasks_left_to_load)
      if(isFetchingMore){
        setTimeout(() => {
          window.scrollTo({left: 0, top: window.scrollY + 300, behavior:"smooth"})
          setIsFetchingMore(false)
        }, 100);
      }
    }}
  );
  
  useEffect(() => {
    window.scrollTo(0,0);
    dispatch(setShowNavbar(true));
  }, [])
  

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, scrollPosition);
      setIsScrollSet(true)
    }, 100);
  }, [scrollPosition])

  useEffect(() => {
    if(activityActiveTab == "user"){
        fetchOnlyUserTDs();
    }
  }, [activityActiveTab, userNumberOfTasksDisplayed])
  

  const handleLoadMoreTasks = () => {
    setIsFetchingMore(true)

    dispatch(
    setUserActivityNumberOfTasksDisplayed(
        userNumberOfTasksDisplayed + ACTIVITY_EXTRA_TASKS_DONE_ON_LOAD_MORE)
    )
  }

  if (isLoadingUserTasks) {
    return (
      <div className="w-full text-center pt-10">
        <Loader  customClasses='!text-primary !w-12 !h-12' />
      </div>
    );
  }

  return (
    <div className='relative pt-[70px] lg:px-[20%]'>
      <div className="user-data-box absolute top-0 left-0 w-full justify-center py-4 shadow-lg  dark:shadow-neutral-800 bg-primary-light">
        <div className="flex justify-center">
          <Avatar
            src={`../../img/avatars/${userAvatarFileName}`}
            className="!flex rounded-full mr-2"
          />
          <div className='grid items-center white-text-with-shadow text-lg'>
            {userName}
            {userName.slice(-1) == "s" ? "' ": "'s "}
            Activity
          </div>
        </div>
      </div>

      {tasksDone && tasksDone.length == 0 && 
        <div className="pt-8 text-lg text-neutral-700 dark:text-neutral-200">
          <p>No activity records were found.</p>
        </div>
      }

        {isRefetchingUserTasks && !isFetchingMore &&
          <div className="py-8">
            <Loader /> 
          </div>
        }

      <div  className={`${isRefetchingUserTasks && !isFetchingMore && '!hidden'} 
        activity-user-tasks-done duration-300 pb-0 overflow-y-scroll`}>
          
          <div className="tasks-done-wrapper p-4">
            {tasksDone && tasksDone.map((td: TaskDone, index) => (
              <div className='mb-6' key={"td-" + index}>
                <h4 className='text-left text-sm mb-2 text-neutral-500 dark:text-neutral-400'>
                  {capitalizeFirstLetter( 
                    formatDistanceToNow(
                        new Date(td.created_at), 
                        {addSuffix:true})
                    )
                  }
                </h4>         
                <div
                    onClick={() => {navigate("/" + td.task.slug)}} 
                    className="grid grid-cols-12 relative mb-4 h-[75px]  dark:text-white text-neutral-600 lg:mx-48 rounded-lg overflow-hidden  border-[2px] dark:border-neutral-900/80 border-white shadow-lg dark:shadow-none dark:bg-neutral-900/70 bg-white">

                    <div className="col-span-2 grid relative overflow-hidden items-center">
                        <div className="task-card-type-icon !w-8 !h-8 absolute top-0 left-0 z-[10] !pl-[3px] !pt-[6px]">
                            <TaskType type={td.task.type} />
                        </div>
                        <LazyImage 
                            imgSrc={td.task.thumbnail_url} 
                            customClasses="rounded-md absolute top-0 left-0 lg:left-0 h-full max-w-[220%]"
                        />
                    </div>

                    <div className="col-span-8 p-2 px-4 grid items-center overflow-y-scroll lg:overflow-auto text-left">
                        <h4 className="text-sm font-medium">{td.task.title}</h4>
                        <p className="text-sm dark:text-neutral-500/70 text-neutral-400/70">
                          {td.task_format?.instructions}
                        </p>
                    </div>

                    <div className={`col-span-2  max-h-[82px] ${getGradeBgColor(td.result)}
                      grid py-5 justify-center`}>
                      <TaskResultGrade
                        result={td.result}
                        customClasses="!h-[35px] !w-[35px] !flex items-center" />
                    </div>

                </div>
              </div>
            ))}
          </div>

          {userTasksLeftToLoad > 0 &&
            <div className="px-8 pb-4 flex justify-center">
              <button
                onClick={() => { handleLoadMoreTasks() } }
                className="form-submit-btn load-more-btn">
                {isFetchingMore && <Loader customClasses='!text-white !w-6 !h-6' />}
                {!isFetchingMore &&
                  <span>Load more <ExpandCircleDownIcon sx={{ mb: "2px" }} /></span>}
              </button>
            </div>}

      </div>
    </div>
  )
}
