import ReactDOM from "react-dom/client";
import "./main.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from 'react-redux';
import { store, useAppSelector } from "./store/store";

const queryClient = new QueryClient();


const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
    <BrowserRouter>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <App/>
        {/* <ReactQueryDevtools/> */}
      </QueryClientProvider>
      </Provider>
    </BrowserRouter>
);