import {Dispatch, SetStateAction, useState} from 'react'
import Modal from 'react-modal';
import CloseIcon from '@mui/icons-material/Close';
import { useMutation } from "react-query";
import { updateAvatar } from "../../../api/user";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { updateStoredAvatar } from "../../../store/features/authSlice";
import Avatar from "@mui/material/Avatar";
import { availableAvatars } from './availableAvatars';
import { Star } from '@mui/icons-material';

interface Props{
  modalIsOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>
}

export default function AvatarModal(props:Props) {

  const dispatch = useAppDispatch();
  const userAvatar = useAppSelector((state) => state.auth.user?.avatar_file_name)
  const userCurrentStars = useAppSelector((state) => state.auth.user?.stars_current)

  const [selectedAvatar, setSelectedAvatar] = useState(userAvatar)

  const {mutate:update, isLoading} = useMutation(updateAvatar);
  

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    update({avatar_file_name: selectedAvatar}, { onSuccess: (resp) => {
      dispatch(updateStoredAvatar(resp));
      props.setIsModalOpen(false);
    }});
  };

  return (
    <Modal 
      isOpen={props.modalIsOpen}
      className="modal-setting-avatar">
        <h2 className="text-center bg-primary text-white text-2xl py-4 font-bricolage">Choose an avatar</h2>

        <div className="icons-wrapper grid grid-cols-12 gap-2 overflow-y-scroll p-2 border-2 border-neutral-200 mb-8">

          {availableAvatars.map((avatar:{file_name:string, cost:number}) => (

            <div
              key={avatar.file_name}
              onClick={() => {
                if(Number(userCurrentStars) >= avatar.cost){
                  setSelectedAvatar(avatar.file_name)
                }
              }}
              className={`col-span-3 shadow-md ${Number(userCurrentStars) < avatar.cost && "opacity-50"}`} 
              >
              <div className="relative bg-white border-[2px] border-primary rounded-t-md overflow-hidden">
                <img src={`../../img/avatars/${avatar.file_name}`} alt="" />
              </div>
              <div className="bg-primary text-center rounded-b-md">
                <p className="white-text-with-shadow">{avatar.cost}
                <Star sx={{ color:"gold", position:"relative", bottom:"2px", ml:"1px" }} /></p>
              </div>
            </div>

          ))}
        </div>

        <div className="update-wrapper grid grid-cols-12 items-center">
          <div className="col-span-6 grid justify-end pr-2">
            <button 
                className="form-submit-btn text-lg" 
                onClick={(e) => handleSubmit(e)}
                >
                Update
            </button>
          </div>
          <div className="col-span-6 grid justify-start pl-2">
            <div className="img-wrapper bg-neutral-200 rounded-full p-2">
              <Avatar 
                src={`../../img/avatars/${selectedAvatar}`}
                className="preview !w-[70px] !h-[70px] duration-500" 
                />
            </div>
          </div>
        </div>

        <button 
            className="btn-close-modal"
            onClick={() => props.setIsModalOpen(false)}
            >
            <CloseIcon className="relative bottom-[2px] mr-1"/>
            CLOSE
        </button>
    </Modal>
  )
}
